<template>
  <a-modal :visible="visible" title="产品案例" destroyOnClose :maskClosable="false"  @ok="handleOk" @cancel="handleCancel">
    <a-form
      ref="form"
      :model="formState"
      name="form"
      :validateOnRuleChange="false"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
      autocomplete="off"
      scrollToFirstError
    >
      <a-form-item
        label="案例标题"
        name="title"
        :rules="[{ required: true, message: '输入案例标题' }]"
      >
        <a-input v-model:value="formState.title" name="title" />
      </a-form-item>
      <a-form-item
        label="案例车型"
        name="vehicleId"
      >
        <a-select
          ref="select"
          showSearch
          v-model:value="formState.vehicleId"
          :options="vehicles"
          :field-names="{ label: 'name', value: 'id'}"
          optionFilterProp="name"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item
        label="所属产品"
        name="productIds"
      >
        <a-select
          ref="select"
          v-model:value="formState.productIds"
          showSearch
          :options="products"
          :field-names="{ label: 'name', value: 'id'}"
          optionFilterProp="name"
          mode="multiple"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item
        label="超级车间施工"
        name="constructedFromGiga"
      >
        <a-switch v-model:checked="formState.constructedFromGiga" />
      </a-form-item>
      <a-form-item
        label="施工门店"
        name="shop"
      >
        <a-select
          ref="select"
          showSearch
          v-model:value="formState.shop"
          :options="gigaShop"
          :field-names="{ label: 'name', value: 'name'}"
          optionFilterProp="name"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item
        label="门店服务经理"
        name="shop"
      >
        <a-select
          ref="select"
          showSearch
          v-model:value="formState.uploader"
          :options="gigaServiceManagers"
          :field-names="{ label: 'name', value: 'value'}"
          optionFilterProp="value"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item
        label="施工单号"
        name="workerSheetNum"
      >
        <a-input v-model:value="formState.workerSheetNum" name="workerSheetNum" />
      </a-form-item>
      <a-form-item
        label="施工价格"
        name="price"
      >
        <a-input v-model:value="formState.price" name="price" />
      </a-form-item>
      <a-form-item label="所属改色膜" name="vehicleFilmId">
        <a-select
          ref="select"
          v-model:value="formState.vehicleFilmId"
          showSearch
          :options="vehicleFilms"
          :field-names="{ label: 'name', value: 'id'}"
          optionFilterProp="name"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item
        label="封面图"
        name="coverImage"
      >
        <GigaUpload v-model:value="formState.coverImage" name="coverImage" :imageUrl="formState.coverImage && formState.coverImage.url || ''" @setImg="setImg($event, 'coverImage')"  />
      </a-form-item>
      <a-form-item
        label="腾讯视频id"
        name="vid"
      >
        <a-input v-model:value="formState.vid" name="vid" />
      </a-form-item>
      <a-form-item
        label="图片列表"
        name="images"
      >
        <GigaUpload v-model:value="formState.images" name="images" :images="formState.images || []" :multiple="true" @sortImg="formState.images = $event" @setImg="setImg($event, 'images', 'multiple')" @removeImg="removeImg($event, 'images')"  />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { ref, watch } from 'vue';
import GigaUpload from '../GigaUpload.vue'
export default {
  name: 'CasebookForm',
  components: {
    GigaUpload
  },
  props: ['visible', 'formData', 'vehicles', 'products', 'gigaShop', 'gigaServiceManagers', 'vehicleFilms'],
  setup(props) {
    const formState = ref(props.formData)
    watch(() => props.formData, (newVal) => formState.value = newVal)
    return {
      formState,
      form: ref(null),
    };
  },
  
  methods: {
    setImg(e, param, multiple) {
      console.log(e)
      let img = e[0]
      let formState = this.formState
      if (multiple) {
        if (!formState[param] || !Array.isArray(formState[param])) formState[param] = []
        formState[param] = formState[param].concat(img)
      } else {
        formState[param] = img
      }
      console.log(formState)
      this.formState = JSON.parse(JSON.stringify(formState))
    },
    removeImg(id, param) {
      console.log(id)
      let formState = this.formState
      let images = formState[param]
      images.forEach((e, index) => {
        if (e.id == id) {
          images.splice(index, 1)
        }
      })
      formState[param] = images
      this.formState = JSON.parse(JSON.stringify(formState))
    },
    handleOk() {
      this.form.validateFields().then(res => {
        let formState = Object.assign(this.formState, res)
        formState.coverImageId = formState.coverImage ? formState.coverImage.id : ''
        formState.shop = formState.shop ? this.gigaShop.filter(e => e.name == formState.shop)[0].value : ''
        formState.photoIds = formState.images ? formState.images.map(e => e.id) : []
        formState.price = formState.price ? formState.price.replace(/¥/g, '').replace(/,/g, '') : ''
        this.$emit('formOk', formState)
      }).catch(err => {
        console.error(err)
        this.$message.error(err.errorFields[0].errors[0])
      })
    },
    handleCancel() {
      this.$emit('formCancel')
    },
  },

}
</script>